.signupModal {
  position: fixed;
  background: var(--tm-c-base-light);
  width: 100%;
  height: calc(100% - 60px);
  z-index: 10;
  top: 0;
}

.signupModalNav {
  width: 100%;
  background: var(--tm-c-base-light);
}

.backButton {
  margin: 10px 20px;
  transition: opacity 0.3s;
}
@media only screen and (min-width: 961px) {
  .backButton:hover {
    opacity: 0.8;
  }
}
.backButton:active {
  opacity: 0.6;
}

.successWrapper {
  font-size: var(--tm-fs-s);
  color: var(--tm-c-dark);
  width: 100%;
  height: 100%;
  background: var(--tm-c-base-light);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 961px) {
  .successWrapper {
    font-size: var(--tm-fs-base);
  }
}

.successContent {
  width: 90%;
  max-width: 600px;
  text-align: center;
}
.successContent .backButton {
  margin: 0 auto;
}
