.joinFlowInfoModal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--tm-fs-base);
}

.joinFlowInfoModalContentWrapper {
  background: var(--tm-c-dark-gray);
  width: 90%;
  height: fit-content;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  position: relative;
}
@media only screen and (min-width: 961px) {
  .joinFlowInfoModalContentWrapper {
    max-width: 800px;
    padding: unset;
  }
}
@media only screen and (min-width: 1401px) {
  .joinFlowInfoModalContentWrapper {
    max-width: 1000px;
  }
}

.closeIcon {
  color: var(--tm-c-base-light);
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media only screen and (min-width: 961px) {
  .joinFlowInfoModalContent {
    width: 60%;
    margin: 80px auto 60px;
  }
}

.joinFlowInfoModalContent h2 {
  font-size: 2.5rem;
}
@media only screen and (min-width: 961px) {
  .joinFlowInfoModalContent h2 {
    font-size: 4rem;
  }
}
.joinFlowInfoModalContent p {
  font-size: var(--tm-fs-s);
}
@media only screen and (min-width: 961px) {
  .joinFlowInfoModalContent p {
    font-size: var(--tm-fs-base);
  }
}

.cta {
  margin: 10px auto;
  transition: opacity 0.3s;
}
@media only screen and (min-width: 961px) {
  .cta:hover {
    opacity: 0.6;
  }
}
.cta:active {
  opacity: 0.4;
}
